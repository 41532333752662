@tailwind base;
@tailwind components;
@tailwind utilities;

button, a{
    @apply transition-colors duration-300 ease-in-out;
}

.fadeAnimation1{
    @apply animate-fade animate-once animate-ease-in-out animate-normal animate-fill-backwards  animate-delay-[100ms] animate-duration-500;
}

.fadeAnimation2{
    @apply animate-fade animate-once animate-ease-in-out animate-normal animate-fill-backwards  animate-delay-[800ms] animate-duration-500;
}

input{
    @apply focus:!ring-0
}


@layer utilities {
    @variants responsive {
      /* Hide scrollbar for Chrome, Safari and Opera */
      .no-scrollbar::-webkit-scrollbar {
          display: none;
      }

      /* Hide scrollbar for IE, Edge and Firefox */
      .no-scrollbar {
          -ms-overflow-style: none;  /* IE and Edge */
          scrollbar-width: none;  /* Firefox */
      }
    }
  }