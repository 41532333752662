@tailwind base;
@tailwind components;
@tailwind utilities;

nav a.active{
    @apply text-main 
}

nav .outline-main-btn.active{
    @apply border-main 
}